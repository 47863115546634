import {
  Optional,
  validateArrayOf,
  validateNotNullishObject,
} from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementProjectRoot,
  IElementType,
  IElementTypeHint,
  isIElementProjectRoot,
  validateIElement,
} from "@faro-lotv/ielement-types";
import {
  ApiResponseError,
  SendAuthenticatedJsonRequestParams,
  Token,
  TokenProvider,
  sendAuthenticatedJsonRequest,
} from "../authentication";
import {
  CaptureTreeEntity,
  CaptureTreeEntityRevision,
  CreateClusterEntitiesParams,
  CreateRootEntityParams,
  CreateScanEntitiesParams,
  RegistrationEdge,
  RegistrationEdgeRevision,
  RegistrationRevision,
  UpdateRegistrationRevisionParams,
  isCaptureTreeEntity,
  isCaptureTreeEntityRevision,
  isRegistrationEdge,
  isRegistrationEdgeRevision,
  isRegistrationRevision,
} from "./capture-tree-types";
import { Mutation } from "./mutations";
import { ProjectApiError } from "./project-api-errors";
import {
  DataSetAreaInfo,
  MutationResult,
  PaginatedAreaVolumeResponse,
  ProjectLabels,
  ProjectStatus,
  SignUrlsParams,
  SignUrlsResponse,
  TaskResult,
  isPaginatedAreaVolumeResponse,
  isProjectLabels,
  isProjectStatus,
  isSignUrlsResponse,
  isTaskResult,
} from "./project-api-types";
import {
  SphereSuccess,
  isSphereAPIValidationError,
  isSphereAPIerror,
} from "./sphere-api/sphere-api-responses";

/**
 * Docs of the Project API:
 * * https://v2.project.api.staging.holobuilder.com/swagger/index.html
 * * https://projectapi.api.eu.dev.farosphere.com/swagger/index.html
 */

const AREA_VOLUME_PAGE_SIZE = 100;
const CAPTURE_TREE_PAGE_SIZE = 100;

export interface GetIElementsParams {
  /** A signal to abort this request */
  signal?: AbortSignal;

  /**
   * Number of items to request per page from the API
   * If not provided, the API will decide the size of the page
   */
  itemsPerPage?: number;

  /**
   * If defined only elements of this type will be returned
   *
   * @deprecated Use `types` instead.
   */
  type?: IElementType;

  /** When provided, only returns elements where the type matches one of the given types */
  types?: IElementType[];

  /**
   * If defined only elements with the provided typeHints will be returned
   *
   * @deprecated Use `typeHints` instead.
   */
  typeHint?: IElementTypeHint[];

  /** If defined only elements with the provided typeHints will be returned */
  typeHints?: IElementTypeHint[];

  /** If defined only elements changed after this date will be returned */
  changedAfter?: Date;

  /** List of nodes to fetch */
  ids?: GUID[];

  /** List of nodes we want to fetch all the children of */
  ancestorIds?: GUID[];

  /** List of nodes we want to fetch the ancestors of  */
  descendantIds?: GUID[];

  /** Callback which is called after the `getIndex` route was called */
  onIndexFetched?(): void;

  /**
   * Callback which is called after a page was fetched with the progress of fetched pages in percent
   * and the current page result
   *
   * @param progress The progress of the page fetching in percent
   * @param onePageResult The result of the current page
   */
  onNextPageFetched?(
    progress: number,
    onePageResult: IElement[],
  ): Promise<void>;

  parentIds?: GUID[];
}

interface PagedRequest {
  /** A signal to abort this request */
  signal?: AbortSignal;

  /** Token to request a follow up page on a paged request */
  nextPageToken: string;
}

interface PagedResponse<T> {
  /** Content of the current page */
  page: T[];

  /**
   * Token that can be used to request the next page
   * If not token is provided, there is no next page
   */
  token: string | null;
}

/**
 * @param itemTypeGuard The type guard to validate the items of the page with
 * @returns A validator to check if the response is a paged response
 */
function isPagedResponse<T>(itemTypeGuard: (data: unknown) => data is T) {
  return (data: unknown): data is PagedResponse<T> => {
    return (
      validateNotNullishObject(data, "PagedResponse") &&
      validateArrayOf({
        object: data,
        prop: "page",
        elementGuard: itemTypeGuard,
      }) &&
      (typeof data.token === "string" || data.token === null)
    );
  };
}

export interface PaginatedIndexResponse {
  /** Number of pages */
  pageCount: number;
  /** Total amount of items in all pages */
  itemCount: number;
  /** Array of all tokens */
  pageTokens: string[];
}

export interface MakeAuthorizedRequestParams {
  /** The request path, _without_ the base URL */
  requestUrl: string;

  /** Additional headers to the http request */
  additionalHeaders?: Record<string, string>;

  /** A signal to abort this fetch */
  signal?: AbortSignal;

  /** Payload to  */
  requestBody?: RequestInit["body"];

  /** Query parameters to attach to the request URL */
  queryParams?: Record<string, string | undefined>;

  /** HTTP method to use for the request (e.g. `"GET"` or `"POST"`) */
  httpMethod?: RequestInit["method"];

  /** Token to use to authenticate the request */
  token?: Token;
}

/**
 * Old params to construct a ProjectApi client using directly a Token and not a TokenProvider
 *
 * @deprecated Please use {@link ProjectApiConstructorParams}
 */
export type LegacyProjectApiConstructorParams = {
  /** ID of the project to work with */
  projectId: GUID;

  /** Token (i.e. JWT) to use for authentication */
  authToken: Token;

  /** Base URL of the Project API (e.g. https://v2.project.api.staging.holobuilder.com, https://projectapi.api.eu.dev.farosphere.com) */
  projectApi: URL;
};

export type ProjectApiConstructorParams = {
  /** ID of the project to work with */
  projectId: GUID;

  /** Token (i.e. JWT) to use for authentication */
  tokenProvider: TokenProvider;

  /** Base URL of the Project API (e.g. https://v2.project.api.staging.holobuilder.com, https://projectapi.api.eu.dev.farosphere.com) */
  projectApi: URL;

  /** A string to identify a backend client in the format client/version */
  clientId?: string;
};

/**
 * Check if a ProjectApiConstructorParams object is legacy
 *
 * @param params The params
 * @returns true if they are the legacy params
 */
function isLegacyParams(
  params: LegacyProjectApiConstructorParams | ProjectApiConstructorParams,
): params is LegacyProjectApiConstructorParams {
  return "authToken" in params;
}

/** A wrapper around the Project API to easily access all important endpoints. */
export class ProjectApi {
  #projectId: GUID;
  #tokenProvider: TokenProvider;
  #projectApiBaseUrl: URL;
  #clientId?: string;

  /** @returns the current linked project id */
  get projectId(): GUID {
    return this.#projectId;
  }

  /**
   * Create a new Project API client
   */
  constructor(params: ProjectApiConstructorParams);
  /**
   * Create a new Project API token
   *
   * @deprecated in favor of the alternative constructor taking a TokenProvider
   */
  constructor(params: LegacyProjectApiConstructorParams);
  /**
   * Constructor private implementations
   *
   * @param params new or legacy params
   */
  constructor(
    params: ProjectApiConstructorParams | LegacyProjectApiConstructorParams,
  ) {
    this.#projectId = params.projectId;
    this.#projectApiBaseUrl = params.projectApi;
    this.#tokenProvider = isLegacyParams(params)
      ? () => Promise.resolve(params.authToken)
      : params.tokenProvider;
    this.#clientId = isLegacyParams(params) ? undefined : params.clientId;
  }

  /** @returns The root IElement of the project with the given ID. */
  // eslint-disable-next-line require-await -- FIXME
  async getRootIElement(): Promise<IElementProjectRoot> {
    return this.makeAuthorizedV1Request<IElementProjectRoot>({
      path: this.#projectId,
      typeGuard: (response): response is IElementProjectRoot =>
        validateIElement(response) && isIElementProjectRoot(response),
    });
  }

  /**
   * Provides a list of all elements of a project.
   * Defaults to request 5000 items per page (the current max for the API)
   */
  async getIElements(): Promise<PagedResponse<IElement>>;

  /**
   * Provides a list of all elements of a project matching the given filters.
   * Defaults to request 5000 items per page (the current max for the API)
   */
  async getIElements(
    params: GetIElementsParams,
  ): Promise<PagedResponse<IElement>>;

  /**
   * Provides a specific page of a paged iElements response.
   * Adding more query filters is not possible here.
   */
  async getIElements(params: PagedRequest): Promise<PagedResponse<IElement>>;

  /**
   * Implementation for the overloads above
   *
   * @param params The filters or next page token to use for the request
   * @returns The IElements of the requested project
   */
  async getIElements(
    params: GetIElementsParams | PagedRequest = {},
  ): Promise<PagedResponse<IElement>> {
    let queryParams: Record<string, string | undefined>;

    if ("nextPageToken" in params) {
      // When requesting a subsequent page, all other query parameters are ignored
      const { nextPageToken } = params;

      queryParams = {
        token: nextPageToken,
      };
    } else {
      const {
        itemsPerPage = 5000,
        type,
        types,
        typeHint,
        typeHints,
        changedAfter,
        ids,
        ancestorIds,
        descendantIds,
        parentIds,
      } = params;

      // Support for the legacy `type` and `typeHint` parameter, until we remove it
      const combinedTypes = [...(type ? [type] : []), ...(types ?? [])];
      const combinedTypeHints = [...(typeHint ?? []), ...(typeHints ?? [])];

      queryParams = {
        itemsPerPage: itemsPerPage.toString(),
        Types: combinedTypes.length > 0 ? combinedTypes.join(",") : undefined,
        TypeHints:
          combinedTypeHints.length > 0
            ? combinedTypeHints.join(",")
            : undefined,
        ChangedAfter: changedAfter?.toISOString(),
        Ids: ids?.join(","),
        AncestorIds: ancestorIds?.join(","),
        DescendantIds: descendantIds?.join(","),
        ParentIds: parentIds?.join(","),
      };
    }

    const pagedResponse = await this.makeAuthorizedV1Request<
      PagedResponse<IElement>
    >({
      signal: params.signal,
      path: `${this.#projectId}/ielements?migrateTypeHints=${IElementTypeHint.area},${IElementTypeHint.dataSession},${IElementTypeHint.dataSetPCloudUpload}`,
      queryParams,
    });

    const malformed = pagedResponse.page.filter((el) => !validateIElement(el));
    if (malformed.length > 0) {
      throw new Error(
        `Project api response contain malformed elements ${malformed
          .map((el) => el.id)
          .join(",")}`,
      );
    }
    return pagedResponse;
  }

  /**
   * @returns All the iElements that matches the query parameters, will handle multiple page requests internally.
   * Callbacks can be used to track the progress of the request.
   */
  async getAllIElements({
    signal,
    onIndexFetched,
    onNextPageFetched,
    ...rest
  }: GetIElementsParams = {}): Promise<IElement[]> {
    try {
      const { pageTokens, pageCount } = await this.getIndex({
        signal,
        ...rest,
      });
      onIndexFetched?.();

      let fetchedPages = 0;

      const pages = await Promise.all(
        pageTokens.map(async (token) => {
          const res = await this.getIElements({ signal, nextPageToken: token });
          fetchedPages += 1;
          const progress = (fetchedPages / pageCount) * 100;
          await onNextPageFetched?.(progress, res.page);
          return res.page;
        }),
      );

      return pages.flat();
    } catch (error) {
      if (signal?.aborted) {
        return [];
      }
      throw error;
    }
  }

  /**
   * Check the status of a project
   *
   * @param signal to abort this request
   * @returns the status of the project
   */
  async getProjectStatus(signal?: AbortSignal): Promise<ProjectStatus> {
    const payload = await this.makeAuthorizedV1Request({
      path: `${this.#projectId}/status`,
      signal,
      typeGuard: (response): response is { status: ProjectStatus } => {
        return (
          validateNotNullishObject(response, "ProjectStatusResponse") &&
          isProjectStatus(response.status)
        );
      },
    });
    return payload.status;
  }

  /**
   * @param signal to abort the request
   * @returns the project labels
   */
  getProjectLabels(signal?: AbortSignal): Promise<ProjectLabels> {
    return this.makeAuthorizedV1Request<ProjectLabels>({
      path: `${this.projectId}/labels`,
      typeGuard: isProjectLabels,
      signal,
    });
  }

  /**
   * Applies mutations on the given project sequentially. If any mutation fails,
   * all subsequent mutations will not be executed, resulting in a 409 Conflict status code.
   *
   * The provided mutations will be forwarded as-is to the API, and the API response will be returned as-is
   *
   * @param mutations Mutations to apply to the project
   * @returns The results of the mutation
   */
  // eslint-disable-next-line require-await -- FIXME
  async applyMutations(mutations: Mutation[]): Promise<MutationResult[]> {
    return this.makeAuthorizedV1Request<MutationResult[]>({
      path: `mutations/${this.#projectId}`,
      httpMethod: "POST",
      requestBody: mutations,
      queryParams: {
        // Do not allow partial mutations to succeed
        // if a mutation in the list fails the entire batch fail
        allowPartialSuccess: "false",
      },
    });
  }

  /**
   * Start an async task to apply mutations on the given project sequentially. If any mutation fails,
   * all subsequent mutations will not be executed, resulting in a 409 Conflict status code.
   *
   * The post will immediately return with results of type pending with an id to ask for the status trough the
   * tasks endpoint
   *
   * @param mutations Mutations to apply to the project
   * @returns The results of the mutation
   */
  // eslint-disable-next-line require-await -- FIXME
  async applyMutationsAsync(mutations: Mutation[]): Promise<GUID> {
    return this.makeAuthorizedV1Request<GUID>({
      path: `mutationsasync/${this.#projectId}`,
      httpMethod: "POST",
      requestBody: mutations,
      queryParams: {
        // Do not allow partial mutations to succeed
        // if a mutation in the list fails the entire batch fail
        allowPartialSuccess: "false",
      },
    });
  }

  /**
   * @returns the current state of an async task
   * @param taskId the id of the task
   */
  // eslint-disable-next-line require-await -- FIXME
  async getTaskStatus(taskId: GUID): Promise<TaskResult> {
    return this.makeAuthorizedV1Request({
      path: `${this.#projectId}/tasks/${taskId}`,
      typeGuard: isTaskResult,
    });
  }

  /**
   * @returns the list of datasets inside an area with their local position in that area
   * @param areaId of the area to query the datasets for
   * @param signal to abort the request
   */
  async queryAreaVolume(
    areaId: GUID,
    signal: AbortSignal,
  ): Promise<DataSetAreaInfo[]> {
    let pageToken: string | undefined | null = undefined;
    const dataSets: DataSetAreaInfo[] = [];

    while (pageToken !== null) {
      const { page, token }: PaginatedAreaVolumeResponse =
        await this.makeAuthorizedV1Request({
          path: `${this.projectId}/ielements/${areaId}/volumeQuery`,
          signal,
          typeGuard: isPaginatedAreaVolumeResponse,
          queryParams: {
            token: pageToken,
            itemsPerPage: AREA_VOLUME_PAGE_SIZE.toString(),
          },
        });
      dataSets.push(...page);
      pageToken = token;
    }

    return dataSets;
  }

  /**
   * @returns the list of capture tree entities for the current main revision of the project
   * @param signal to abort the request
   */
  async getCaptureTreeForMainRevision(
    signal?: AbortSignal,
  ): Promise<CaptureTreeEntity[]> {
    let pageToken: string | undefined | null = undefined;
    const entities: CaptureTreeEntity[] = [];
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<CaptureTreeEntity> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/capture-tree`,
          signal,
          typeGuard: isPagedResponse(isCaptureTreeEntity),
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      entities.push(...page);
      pageToken = token;
    }

    return entities;
  }

  /**
   * @returns the list of capture tree entities for a specific registration revision of the project
   * @param registrationRevisionId the id of the registration revision
   * @param signal to abort the request
   */
  async getCaptureTreeForRegistrationRevision(
    registrationRevisionId: GUID,
    signal?: AbortSignal,
  ): Promise<CaptureTreeEntityRevision[]> {
    let pageToken: string | undefined | null = undefined;
    const entityRevisions: CaptureTreeEntityRevision[] = [];
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<CaptureTreeEntityRevision> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-revisions/${registrationRevisionId}`,
          signal,
          typeGuard: isPagedResponse(isCaptureTreeEntityRevision),
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      entityRevisions.push(...page);
      pageToken = token;
    }

    return entityRevisions;
  }

  /**
   * @returns the requested registration revision of the project
   * @param revisionId the id of the revision to query
   * @param signal to abort the request
   */
  getRegistrationRevision(
    revisionId: GUID,
    signal?: AbortSignal,
  ): Promise<RegistrationRevision> {
    return this.makeAuthorizedV1RestRequest({
      path: `projects/${this.projectId}/registration-revisions/${revisionId}/info`,
      signal,
      typeGuard: isRegistrationRevision,
    });
  }

  /**
   * @returns the list registration revisions of the project
   * @param signal to abort the request
   */
  async getAllRegistrationRevisions(
    signal?: AbortSignal,
  ): Promise<RegistrationRevision[]> {
    let pageToken: string | undefined | null = undefined;
    const registrationRevisions: RegistrationRevision[] = [];
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<RegistrationRevision> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-revisions`,
          signal,
          typeGuard: isPagedResponse(isRegistrationRevision),
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      registrationRevisions.push(...page);
      pageToken = token;
    }

    return registrationRevisions;
  }

  /**
   * @returns the list of registration edges for the current main revision of the project
   * @param signal to abort the request
   */
  async getAllRegistrationEdgesForMainRevision(
    signal?: AbortSignal,
  ): Promise<RegistrationEdge[]> {
    let pageToken: string | undefined | null = undefined;
    const edges: RegistrationEdge[] = [];
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<RegistrationEdge> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-edges`,
          signal,
          typeGuard: isPagedResponse(isRegistrationEdge),
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      edges.push(...page);
      pageToken = token;
    }

    return edges;
  }

  /**
   * @returns the list of registration edges for a specific revision of the project
   * @param registrationRevisionId the id of the registration revision
   * @param signal to abort the request
   */
  async getRegistrationEdgesForRevision(
    registrationRevisionId: GUID,
    signal?: AbortSignal,
  ): Promise<RegistrationEdgeRevision[]> {
    let pageToken: string | undefined | null = undefined;
    const entityRevisions: RegistrationEdgeRevision[] = [];
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<RegistrationEdgeRevision> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-revisions/${registrationRevisionId}/edges`,
          signal,
          typeGuard: isPagedResponse(isRegistrationEdgeRevision),
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      entityRevisions.push(...page);
      pageToken = token;
    }

    return entityRevisions;
  }
  /**
   * @throws error if the registration revision was not successfully applied
   * @returns a promise that will resolve or rejects once the operation is completed
   * @param captureTreeEntityIds The ids of the capture tree entities to include in the revision
   * @param registrationEdgeIds The ids of the registration edges to include in the revision
   */
  async createRegistrationRevision(
    captureTreeEntityIds: GUID[],
    registrationEdgeIds: GUID[],
  ): Promise<RegistrationRevision> {
    return await this.makeAuthorizedV1RestRequest<RegistrationRevision>({
      path: `projects/${this.#projectId}/registration-revisions`,
      httpMethod: "POST",
      requestBody: {
        captureTreeEntityIds,
        registrationEdgeIds,
      },
    });
  }

  /**
   * @throws error if the registration revision was not successfully applied
   * @returns a promise that will resolve or rejects once the operation is completed
   * @param registrationRevisionId the id of the registration revision to be applied to main
   */
  applyRegistrationRevisionToMain(registrationRevisionId: GUID): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/apply`,
      httpMethod: "POST",
      requestBody: { projectId: this.#projectId, registrationRevisionId },
    });
  }

  /**
   * @returns the updated registration revision
   */
  async updateRegistrationRevision({
    registrationRevisionId,
    state,
    reportUri,
    projectPointCloud,
  }: UpdateRegistrationRevisionParams): Promise<RegistrationRevision> {
    return await this.makeAuthorizedV1RestRequest<RegistrationRevision>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}`,
      httpMethod: "PATCH",
      requestBody: { state, reportUri, projectPointCloud },
    });
  }

  /**
   * @throws error if the root entity was not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  createRootEntityForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  }: CreateRootEntityParams): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/root`,
      httpMethod: "PATCH",
      requestBody,
    });
  }

  /**
   * @throws error if the cluster entities were not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  createClusterEntitiesForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  }: CreateClusterEntitiesParams): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/clusters`,
      httpMethod: "PATCH",
      requestBody,
    });
  }

  /**
   * @throws error if the scan entities were not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  createScanEntitiesForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  }: CreateScanEntitiesParams): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/scans`,
      httpMethod: "PATCH",
      requestBody,
    });
  }

  /**
   * @returns the list of areas an element is contained in
   * @param elementId of the area to query the datasets for
   * @param signal to abort the request
   */
  async queryAreaVolumeInverse(
    elementId: GUID,
    signal: AbortSignal,
  ): Promise<DataSetAreaInfo[]> {
    let pageToken: string | undefined | null = undefined;
    const dataSets: DataSetAreaInfo[] = [];

    while (pageToken !== null) {
      const { page, token }: PaginatedAreaVolumeResponse =
        await this.makeAuthorizedV1Request({
          path: `${this.projectId}/ielements/${elementId}/inverseVolumeQuery`,
          signal,
          typeGuard: isPaginatedAreaVolumeResponse,
          queryParams: {
            token: pageToken,
            itemsPerPage: AREA_VOLUME_PAGE_SIZE.toString(),
          },
        });
      dataSets.push(...page);
      pageToken = token;
    }

    return dataSets;
  }

  /**
   *
   * @param projectName The name of the project being created in Sphere.
   * @param workspaceId The workspace in which to insert the new project
   * @param JWTtoken The token to use to authenticate the request
   * @returns a core API response
   */
  public async linkProjectToSphere(
    projectName: string,
    workspaceId: GUID | null,
    JWTtoken: Token,
  ): Promise<SphereSuccess> {
    const path = `${this.#projectId}/proxy`;

    try {
      return await this.makeAuthorizedV1Request<SphereSuccess>({
        path,
        additionalHeaders: {
          "Access-Control-Allow-Credentials": "true",
        },
        httpMethod: "POST",
        requestBody: {
          type: "ConnectWithSphereRequest",
          projectName,
          workspaceId,
        },
        // eslint-disable-next-line require-await -- FIXME
        tokenProvider: async () => JWTtoken,
      });
    } catch (error) {
      if (error instanceof ApiResponseError) {
        const message = error.body;
        let newMsg = message;
        // We determine whether the error we are getting is a Sphere API error,
        // in order to provide a more meaningful and easier error message.
        if (isSphereAPIerror(message)) {
          if (isSphereAPIValidationError(message)) {
            newMsg = `Sphere validation error: ${message.error}`;
          } else {
            newMsg = `Sphere API error: ${message.error}`;
          }
        }
        // Using `ProjectApiError` instead of `ApiResponseError` for backwards compatibility
        throw new ProjectApiError(error.status, error.statusText, newMsg);
      } else {
        throw error;
      }
    }
  }

  /**
   * @param params {@link GetIElementsParams} parameters to filter the index.
   * @returns a list of tokens for all pages of the project.
   */
  public async getIndex(
    params: GetIElementsParams,
  ): Promise<PaginatedIndexResponse> {
    const path = `${this.#projectId}/ielements/index`;
    return await this.makeAuthorizedV1Request<PaginatedIndexResponse>({
      path,
      signal: params.signal,
      queryParams: {
        itemsPerPage: params.itemsPerPage?.toString(),
        Type: params.type,
        Types: params.types?.join(","),
        TypeHints: params.typeHints?.join(","),
        ChangedAfter: params.changedAfter?.toISOString(),
        Ids: params.ids?.join(","),
        AncestorIds: params.ancestorIds?.join(","),
        DescendantIds: params.descendantIds?.join(","),
        ParentIds: params.parentIds?.join(","),
      },
      typeGuard: (response): response is PaginatedIndexResponse => {
        return (
          validateNotNullishObject(response, "PaginatedIndexResponse") &&
          typeof response.pageCount === "number" &&
          typeof response.itemCount === "number" &&
          validateArrayOf({
            object: response,
            prop: "pageTokens",
            elementGuard: (x) => typeof x === "string",
          })
        );
      },
    });
  }

  /**
   * Request the core api to sign some urls attached to a specific element
   *
   * @returns the signed urls and their new expire date
   */
  public async signUrls({
    signal,
    elements,
  }: SignUrlsParams): Promise<SignUrlsResponse> {
    return await this.makeAuthorizedV1Request<SignUrlsResponse>({
      httpMethod: "POST",
      path: `signurls/${this.#projectId}`,
      signal,
      requestBody: {
        elements,
      },
      typeGuard: isSignUrlsResponse,
    });
  }

  /**
   * Send a call to the v1 standard endpoint of the project api
   * Used for IElements and mutations
   *
   * @param params for the request
   * @returns a promise with the backend response
   */
  private makeAuthorizedV1Request<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return this.makeAuthorizedRequest<T>({
      ...params,
      path: `/v1/${params.path}`,
    });
  }

  /**
   * Send a call to the v1 rest endpoint of the project api
   * Used for the capture tree and newer apis that uses a REST approach instead of mutations
   *
   * @param params for the request
   * @returns a promise with the backend response
   */
  private makeAuthorizedV1RestRequest<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return this.makeAuthorizedRequest<T>({
      ...params,
      path: `/v1.rest/${params.path}`,
    });
  }

  /**
   * Make an authorized request to the Project API.
   *
   * This `baseUrl`, `clientId`, and `tokenProvider` are set automatically.
   *
   * @param params The parameters to pass to the `sendAuthenticatedJsonRequest` utility.
   * @returns The deserialized response of the API.
   */
  private makeAuthorizedRequest<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId" | "baseUrl"
    >,
  ): Promise<T> {
    try {
      return sendAuthenticatedJsonRequest({
        ...params,
        baseUrl: this.#projectApiBaseUrl.toString(),
        path: params.path,
        tokenProvider: this.#tokenProvider,
        clientId: this.#clientId,
        // TODO: Properly enforce type guards and add missing type validation
        // https://faro01.atlassian.net/browse/SWEB-2572
        typeGuard: params.typeGuard
          ? params.typeGuard
          : (body: unknown): body is T => true,
      });
    } catch (error) {
      if (error instanceof ApiResponseError) {
        // To maintain backwards compatibility
        throw new ProjectApiError(error.status, error.statusText, error.body);
      }
      throw error;
    }
  }
}
